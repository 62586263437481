<template>
  <div
    class="home_planes"
    :style="`background-image: url(${require('@/assets/landing/trama.svg')})`"
  >
    <v-container class="content-home">
      <h1 class="texto-planes text-center">
        Tenemos el <span style="color: #17233f"> plan perfecto</span>
      </h1>
    </v-container>
    <div class="banner"></div>
    <v-container class="wrap-section-planes mb-12">
      <!-- <div class="text-titulo-wrap flex-center">
        <h1>Tenemos el plan perfecto</h1>
      </div> -->
      <div class="wrap-planes">
        <div
          class="wrap-plan"
          v-for="(plan, index) in planesfilters"
          :key="index"
        >
          <div :class="{ 'titulo-plan': true, dark: plan.dark }">
            <span>{{ plan.title }}</span>
          </div>
          <div class="price-detalle">
            <div>
              <span class="free">GRATIS</span>
            </div>
            <div>
              <span :class="{ price: true, subrrallado: plan.discont }">
                {{ plan.price | currency }}
              </span>
              <span style="margin-left: -5px">{{ plan.time }}</span>
            </div>
          </div>
          <div class="descrition my-6">
            <ul style="list-style-type: circle !important">
              <li
                class="text-caption"
                v-for="(list, index) in plan.description"
                :key="index"
              >
                {{ list }}
              </li>
            </ul>
            <!-- <v-btn
              class="mt-6 btn-saber"
              style="border-radius: 15px"
              block
              color="#00cdbc"
            >
              Saber más
            </v-btn> -->
          </div>
        </div>
      </div>
      <tablee class="mt-12" />
    </v-container>
    <v-container class="contact mb-12">
      <h1>Somos el aliado que te apoya en cada paso</h1>
      <p>
        Ya estás más cerca de incrementar tus ventas, completa el formulario y
        nos pondremos en contacto lo antes posible
      </p>
      <v-row>
        <v-col cols="12" md="6" sm="6" lg="6">
          <img
            src="@/assets/landing/menu3d-01.jpg"
            width="100%"
            alt=""
            srcset=""
          />
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="6">
          <v-form
            class="pa-4"
            style="background-color: #fff"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              placeholder="Nombre completo"
              filled
              :color="color"
              :rules="requerided"
              v-model="form.name"
              style="border-radius: 15px"
              rounded
            ></v-text-field>
            <v-text-field
              placeholder="Ciudad"
              style="border-radius: 15px"
              filled
              :color="color"
              :rules="requerided"
              v-model="form.city"
              rounded
            ></v-text-field>
            <v-text-field
              placeholder="Nombre de tu negocio"
              filled
              :color="color"
              rounded
              :rules="requerided"
              v-model="form.nameBusiness"
              style="border-radius: 15px"
            ></v-text-field>

            <v-row>
              <v-col cols="2">
                <div class="wrap-flag">
                  <v-menu
                    offset-y
                    transition="slide-x-transition"
                    left
                    class="color-bg"
                    bottom
                    rounded="lg"
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                        <img
                          :src="`${require(`@/Countries/` + Country.flag)}`"
                          alt=""
                          srcset=""
                        />
                        <!-- <p>+{{ Country.callingCodes[0] }}</p> -->
                      </div>
                    </template>
                    <v-list height="250px" class="list">
                      <v-list-item
                        v-for="(item, index) in Countries"
                        :key="index"
                        class="list-item"
                        @click="Country = item"
                      >
                        <div class="img-text">
                          <img
                            :src="`${require(`@/Countries/` + item.flag)}`"
                            alt=""
                            srcset=""
                          />
                          <p class="ml-2">
                            <b>{{ item.name }} </b> +{{ item.callingCodes[0] }}
                          </p>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  placeholder="Teléfono"
                  style="border-radius: 15px"
                  filled
                  :color="color"
                  :prefix="`+${Country.callingCodes[0]}`"
                  @keydown="isNumber($event)"
                  :rules="requerided"
                  v-model="form.phone"
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              placeholder="Correo"
              :rules="emailRules"
              filled
              :color="color"
              v-model="form.email"
              style="border-radius: 15px"
              rounded
            ></v-text-field>
            <v-btn
              color="#00cdbc"
              block
              :loading="btnLoading"
              style="border-radius: 15px; color: #fff"
              rounded
              @click="validate"
              height="55px"
            >
              Enviar
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import phones from "@/components/Landing/phone.vue";
import Footer from "@/components/Landing/footer.vue";
import countries from "@/Countries/countries.json";
import country from "@/Countries/country.json";
import tablee from "@/components/Landing/table-check.vue";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "Landing",
  components: {
    Footer,
    phones,
    tablee,
  },
  data: () => ({
    valid: true,
    form: {
      name: "",
      phone: "",
      city: "",
      email: "",
      nameBusiness: "",
    },
    Countries: countries,
    btnLoading: false,
    Country: country,
    color: "#00cdbc",
    Loding: true,
    procesos: [
      {
        title: "Tienda en línea",
        text: "Compra con en tu menú",
        icon: require("@/assets/icons/store.svg"),
        style: {
          "background-color": "#00CDBC",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
      {
        title: "Pedido a Menu App, Portal Web o Whatsapp",
        icon: require("@/assets/icons/order.svg"),
        text: "Visualiza tus pedidos en cualquiera de estas Plataformas",
        style: {
          "background-color": "#ff5900",
          "box-shadow": "#FF5900 50% 0px 10px 30px",
        },
      },
      {
        title: "Entrega",
        icon: require("@/assets/icons/check.svg"),
        text: "Configura varios tipos de entrega \n• DineIn \n• Delivery \n• Pickup",
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
    ],
    metodos_pay: [
      {
        title: "Ocho pay",
        icon: require("@/assets/icons/l.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      // {
      //   title: "Efectivo",
      //   icon: require("@/assets/icons/money.svg"),
      //   style: {
      //     "background-color": "#00cdbc",
      //   },
      // },

      // {
      //   title: "Otros",
      //   icon: require("@/assets/icons/edit.svg"),
      //   style: {
      //     "background-color": "#6C99FF",
      //     // "box-shadow": "#6CFFFF 0px 10px 30px",
      //   },
      // },
    ],
    ventajas: [
      {
        title: "Acceso Web",
        icon: require("@/assets/icons/web.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
      {
        title: "Botón de pago + delivery",
        icon: require("@/assets/icons/delivery-pay.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "rgba(0, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Soporte 24/7",
        icon: require("@/assets/icons/help.svg"),
        style: {
          "background-color": "#ff6c6c",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Tracking de la orden",
        icon: require("@/assets/icons/tracking.svg"),
        style: {
          "background-color": "#1d2d51",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      {
        title: "Tu logo, tus colores",
        icon: require("@/assets/icons/theme.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Administra tus productos",
        icon: require("@/assets/icons/list-product.svg"),
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Pickup, delivery o servir en mesa",
        icon: require("@/assets/icons/dineIn.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "QR - NFC",
        icon: require("@/assets/icons/qr.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
    ],
    options: [
      {
        title: "Administra tus Productos",
        icon: require("@/assets/icons/product.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "#6CFFFF 0px 10px 10px",
        },
      },
      {
        title: "Configura tu tema",
        icon: require("@/assets/icons/theme.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Reporte de ventas",
        icon: require("@/assets/icons/grafic.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      {
        title: "Descarga tus reportes",
        icon: require("@/assets/icons/report.svg"),
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Configura tu menú",
        icon: require("@/assets/icons/custom.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Zonas delivery",
        icon: require("@/assets/icons/location.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Genera tu QR",
        icon: require("@/assets/icons/qr.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Configuración NFC",
        icon: require("@/assets/icons/NFC.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
    ],
    planes: [
      {
        title: "Básico",
        price: 350,
        time: "/mes",
        dark: false,
        discont: true,
        description:
          "0% comisiones.•Acceso para 1 usuarios.•Personalización de tu restaurante (logo y colores).•Categorías: ilimitados.•Productos: 500.•Carga y actualiza productos vía excel.•Almacena hasta 1GB.•Opción para ordenar en mesa, delivery o pickup.•Recibe órdenes vía Portal Web y Whatsapp.•Portal Web con dashboard y reportería.•Módulo Pagos para cobro en línea con tarjetas.•Módulo Descuentos.•Soporte vía email.",
      },
      {
        title: "Ninja",
        price: 850,
        time: "/mes",
        discont: true,
        dark: true,
        description:
          "0% comisiones.•Acceso para 5 usuarios. •Personalización de tu restaurante (logo y colores).•Categorías: ilimitados.•Productos: 500.•Carga y actualiza productos vía excel.•Almacena hasta 2GB.•Opción para ordenar en mesa, delivery o pickup.•Recibe órdenes vía Portal Web y Whatsapp.•Portal Web con dashboard y reportería.•Módulo Pagos para cobro en línea con tarjetas.•Módulo Descuentos.•Comparte Link de Tracking con tus clientes en órdenes tipo delivery.•Módulo Mensajería para envíos SMS a tu base de clientes.•Módulo Sorteos para giveaways y promos.•Soporte vía Whatsapp.",
      },
    ],
    requerided: [(v) => !!v || "Requerido"],
    emailRules: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Ingresa un correo valido",
    ],
  }),

  computed: {
    planesfilters() {
      let array = this.planes;
      array.forEach((element) => {
        element.description = element.description.split("•");
      });
      return array;
    },
  },
  methods: {
    ...mapActions(["Alert_", "addData_info"]),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    cleanForm() {
      this.form = {
        name: "",
        phone: "",
        city: "",
        email: "",
        nameBusiness: "",
      };
    },
    validate() {
      this.$refs.form.validate();
      this.btnLoading = true;
      if (this.$refs.form.validate()) {
        var code = this.Country.callingCodes[0];
        this.form.phone = code + this.form.phone;

        db.collection("menuRequest")
          .add(this.form)
          .then((response) => {
            this.Alert_({
              text: "Tu solicitud fue enviada con éxito, pronto nos pondremos en contacto",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "fa-solid fa-check",
              type: "success",
            });
          });
        this.btnLoading = false;
      } else {
        this.Alert_({
          text: "😬 Completa tu información",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-alert",
          type: "error",
        });
        this.btnLoading = false;
      }
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
@mixin display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center {
  @include display-center;
}
p {
  color: #6c6c6c;
}
.flecha-next {
  position: absolute;
  right: 30px;
  top: 50%;
  @include responsive(mobile) {
    top: 90%;
    bottom: 0px;
    right: calc(50% - 20px);
    height: 20px;
    transform: rotateZ(90deg);
  }
}
.inner {
  perspective: 1000px;
  img {
    transition: 0.3s;
    transform: rotateY(20deg);
    border-radius: 12px;
    box-shadow: #00544d2b 0px 20px 30px;

    &:hover {
      box-shadow: #00544d00 0px 0px 0px;

      transform: rotateY(0deg);
    }
  }
}
.two_phone {
  @include responsive(desktop) {
    width: 80%;
  }
  @include responsive(small) {
    width: 50%;
  }
}
.texto-planes {
  font-size: 3em;
  font-weight: 800;
  color: #fff;
}

.compu-muck {
  width: 45%;
  bottom: -50px;
  position: absolute;
  @include responsive(mobile) {
    position: relative !important;
    width: 100%;
    bottom: 50px;
  }
  @include responsive(small) {
    position: relative !important;
    width: 70%;

    bottom: 50px;
  }
  @include responsive(desktop) {
    position: relative !important;
    width: 80%;
    bottom: 0px;
  }
}
.home_planes {
  width: 100%;
  position: relative;
  height: auto;
  padding-top: 100px;
  background-size: contain;
  background-repeat: repeat-y;
  @include responsive(mobile) {
    background-image: url("../assets/landing/trama-mobile.svg") !important ;
  }
  .content-home {
    height: 300px;
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .banner {
    height: 600px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-image: radial-gradient(
      circle at 10% 50%,
      #00cdbc,
      #00b5cd
    ) !important;
    clip-path: ellipse(150% 70% at 0% 25%);
    z-index: 2;
  }

  .wrap-content-home {
    z-index: 100;
    height: 75vh;
    position: relative;
    @include display-center;
    @include responsive(mobile) {
      height: auto;
      margin-top: 20px;
    }
    .text-titulo {
      font-weight: 800;
      font-size: 40px;
      @include responsive(mobile) {
        height: auto;
        margin-top: 20px;
        line-height: 40px;
        padding-left: 10px;
      }
    }
  }
}
.procesos-title {
  @include responsive(mobile) {
    text-align: center;
  }
}
// ul {
//   padding-left: 5px !important;
//   li {
//     list-style: none;
//   }
// }

.lista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.ventajas-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include responsive(mobile) {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
  flex-wrap: wrap;
  .wrap-cuadro-v {
    width: 100%;
    height: 90px;
    @include responsive(mobile) {
      height: auto;
      width: 50% !important;
      text-align: center;
    }
    @include display-center;
    flex-direction: row;
    align-items: center !important;
    justify-content: flex-start;
    @include responsive(mobile) {
      flex-direction: column !important;
      justify-content: center !important;
    }

    .cuadro {
      width: 60px !important;
      height: 60px;
      border-radius: 15px;
      @include display-center;
      img {
        width: 55%;
      }
    }
    p {
      margin-left: 10px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}
.lista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.btn-saber {
  color: #fff;

  height: 50px !important;
  box-shadow: #1c899f57 0px 0px 0px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: #1c899f57 0px 10px 30px;
  }
}
.metodos {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include responsive(mobile) {
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .wrap-cuadro-v {
    width: 150px;
    height: 150px;
    @include display-center;
    flex-direction: column;

    .cuadro {
      width: 160px;
      height: 50px;
      border-radius: 20px;
      transition: 0.3s ease-in-out;
      @include display-center;

      &:hover {
        transform: translateY(-5px);
        box-shadow: #ff7d36a1 0px 5px 20px;
      }
    }
    p {
      margin-bottom: 0px;
    }
  }
}

.metodos-pago {
  height: 300px;
  margin-top: 100px;
  width: 100%;
  background-color: #8feee6ba;
  @include display-center;
  flex-direction: column;
  @include responsive(mobile) {
    height: auto;
  }
}
.wrap-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3f3f3f;
  @include responsive(mobile) {
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  .cuadro {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    @include responsive(mobile) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include display-center;
    img {
      width: 25px;
    }
  }
  p {
    text-align: center;
    margin-bottom: 0;
    line-height: 18px;
    margin-left: 10px;
  }
}
.procesos {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: #3f3f3f;
  padding-right: 30px;
  @include responsive(mobile) {
    width: 70%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
  }
  .cuadro {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    background-color: #00cdbc;
    @include display-center;
    img {
      width: 50%;
    }
  }
  h3 {
    margin-top: 20px;
  }

  p {
    white-space: pre-wrap !important;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    margin-bottom: 0;
    margin-top: 10px;
  }
}
.text-titulo-wrap {
  flex-direction: column !important;
  margin-top: 20px;
  min-width: auto;
  max-width: 500px;
  text-align: center;
}
p {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  margin-bottom: 0;
  margin-top: 10px;
}

.wrap-section-planes {
  position: relative;
  z-index: 100;
  @include display-center;
  flex-direction: column;
  .wrap-planes {
    width: 800px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    @include responsive(mobile) {
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: auto;
    }
    .wrap-plan {
      margin-top: 50px;
      width: 350px;
      border-radius: 15px;
      box-shadow: #002e3720 0px 10px 30px;
      padding: 0px 20px 20px 20px;
      background-color: #fff;
      @include display-center;
      flex-direction: column;
      .price-detalle {
        .price {
          text-align: center;
          font-weight: 800;
          font-size: 40px;
          color: #181818;
        }
        .free {
          font-weight: 800;
          font-size: 50px;
          color: #00cdbc;
        }
      }
      .titulo-plan {
        transform: translateY(-50%);
        @include display-center;
        width: calc(100% - 20%);
        border-radius: 15px;
        background-color: #fff;
        height: 50px;
        box-shadow: #1c899f57 0px 10px 30px;
        span {
          font-size: 30px;
          font-weight: 900;
        }
      }
      .dark {
        background-color: #00cdbc !important;
         background-image: radial-gradient(
      circle at 80% 0%,
      #00cdbc,
      #00b5cd
    ) !important;
        color: #fff !important;
      }
    }
  }
}
.subrrallado {
  text-decoration-line: line-through;
  text-decoration-color: rgb(247, 102, 102);
}
.contact {
  min-height: 500px;
  height: auto;
  text-align: center;
  h3 {
    max-width: 550px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.227) !important;
}
.wrap-bandera {
  width: 100px;
  height: 20px;
  padding: 5px;
}
.wrap-flag {
  position: relative;
  .flag {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 35px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}

.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 30px;
    height: 23px;
    border-radius: 5px;
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
</style>
